@import '../../vars/variables';

.navBar{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 3rem;
    background-color:$background_light ;
    border-top: 1px solid $background_lila;
}


.navButton{
  color:$font_color;
  &#isSelected{
    color: $highlight_color
  }

}