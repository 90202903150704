@import 'src/vars/variables';

.stored-text-entry-wrapper{
    display:flex;
    background-color: $background_light;
    margin: 5px 0;
    padding: 5px;
    height: 4rem;
    border: $item-border;
    border-radius: $item-border-radius;
    box-shadow: 2px 2px $background_lila;
    font-size: 1rem;
    align-items: center;
}

.text {
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    margin-left: 5px;
  
}

.icon-wrapper {
    margin: 0 5px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover{
        color: white;
    }
}