@import 'src/vars/variables';

.tts-area{
    background-color: $background_dark;
    color: $font_color;
    border:0;
    border-radius: 5px;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;

    &:focus{
        outline: none;
    }
}

.tts-area-wrapper{
    border-bottom: 1px solid white;
}

.playButtonWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $highlight_color;
    font-size: 2.5rem;
    width: 50%;
    margin:  2px auto;
    background-color: $background_light;
    border: $item-border;
    border-radius: $item-border-radius;
   

    *{
        margin: 0 7px;
        
    }
}



