@import '/src/vars/variables';

.reaction-board{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}

.reaction-button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $background_light;
    font-size: 1.5rem;
    height: 4rem;
    width: 3.5rem;
    margin: 2px;
    border-radius:5px;
    border: 1px solid rgb(117, 114, 114);

    &.save-mode{
        border: 1px solid $background_lila;
    }
}

.reaction-button-badge {
    background-color: $background_dark;
    border-radius: 130px;
    text-align: center;
    font-size: .8rem;
    padding: 0 .2rem;
    width: 2rem; 
    }
