@import "./vars/variables";

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./fonts/Raleway-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 100;
  src: local('Raleway'), url(./fonts/Raleway-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 200;
  src: local('Raleway'), url(./fonts/Raleway-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 300;
  src: local('Raleway'), url(./fonts/Raleway-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 500;
  src: local('Raleway'), url(./fonts/Raleway-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 600;
  src: local('Raleway'), url(./fonts/Raleway-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  src:
   local('Raleway'), url(./fonts/Raleway-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  src: local('Raleway'), url(./fonts/Raleway-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'ShadowsIntoLight';
  src: local('ShadowsIntoLight'), url(./fonts/ShadowsIntoLight-Regular.ttf) format('truetype');
}

body {
  
  font-family: -apple-system, BlinkMacSystemFont,'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background_dark;
  color: $font_color;
  margin: 0 2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

input{
  padding:.5rem;
  margin-top:0.5rem;
  border: 0;
  border-radius: 0.2rem;
  width: 100%;
  color: $font_color;
}

input[type=text] {
  background-color: $background_light;
}

input[type=submit] {
  background-color: $highlight_color;
  color: $background_light;
  font-weight: 600;

}

label{
  font-size: 0.8rem;
  display: block;
  padding-top: 1rem;
}

select{
  padding:.5rem;
  margin-top:0.5rem;
  background-color: $background_light;
  width: 100%;
  color: $font_color;
  border: 0;
  border-radius: 0.2rem;
}